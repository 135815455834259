<template>
  <div
    class="flex flex-col border border-[#D9E4EA] p-3 space-y-1 max-w-[600px]"
  >
    <div>
      <div class="flex justify-start">
        <button
          class="flex gap-2"
          @click.stop="toggleStudyDesignStatusCheckbox()"
        >
          <Checkbox
            v-model="studyDesignNotApplicableCheckbox"
            class="w-5 h-5"
          ></Checkbox>
          <p class="text-primary">N/A</p>
        </button>
      </div>
    </div>
    <div>
      <GenericAutoComplete
        label="Countries"
        :items="countries"
        :item-options="{
          displayProperty: 'name',
          filterProperties: ['code', 'name'],
          valueProperty: 'code',
        }"
        :placeholder="'select country'"
        class="w-full"
        :shadow="false"
        :model-value="''"
        @update:model-value="(value: any) => addCountry(value)"
      >
      </GenericAutoComplete>

      <div class="flex flex-wrap gap-2 items-center">
        <div
          v-for="country in studyDesign.countries"
          :key="'contry-code-' + country"
          class="flex items-center text-xs border-2 gap-1 px-3 py-1 rounded-full text-black leading-none bg-slate-200"
        >
          {{ country }}
          <button
            class="hover:bg-transparent"
            @click.stop="removeCountry(country)"
          >
            <XCircleIcon class="w-4 h-4" />
          </button>
        </div>
      </div>
    </div>

    <div>
      <GenericAutoComplete
        v-model="originalStudyDesign.direction"
        label="Direction"
        placeholder="Direction"
        :item-options="{
          displayProperty: 'text',
          valueProperty: 'value',
          filterProperties: ['text', 'value'],
        }"
        :items="directions"
        @update:model-value="
          (value: any) => updateStudyDesignProperty('direction', value)
        "
      />
    </div>

    <div v-if="studyDesign.direction !== StudyDesignDirection.Retrospective">
      <GenericAutoComplete
        v-model="originalStudyDesign.type"
        label="Type"
        placeholder="Type"
        :item-options="{
          displayProperty: 'text',
          valueProperty: 'value',
          filterProperties: ['text', 'value'],
        }"
        :items="types"
        @update:model-value="
          (value: any) => updateStudyDesignProperty('type', value)
        "
      />
    </div>

    <div>
      <p class="text-sm text-primary font-medium">Design</p>

      <InlineTextInput
        v-model="studyDesign.design"
        class="h-full w-full"
        editor-classes="h-full"
        :multiline="true"
        @save="updateStudyDesignProperty('design', $event)"
      />
    </div>

    <div>
      <div class="flex-col">
        <p class="text-sm text-primary font-medium">Inclusion criteria</p>
        <div class="flex flex-wrap gap-2 items-center">
          <div
            v-for="criterion in studyDesign.inclusionCriteria"
            :key="'studyDesign-inclusion-criterion-' + criterion"
            class="flex items-center text-sm gap-1 px-3 py-1 min-h-6 rounded-full text-green-800 bg-green-200 leading-none"
          >
            {{ criterion }}
            <button @click="removeCriterion('inclusionCriteria', criterion)">
              <XCircleIcon class="w-5 h-5" />
            </button>
          </div>
          <FloatingTextInput @save="addCriterion('inclusionCriteria', $event)">
            <template #activator="{ show }">
              <button
                key=""
                class="text-sm border-2 p-1 px-3 rounded-full text-primary border-blue-200 border-dashed"
                @click.stop="show"
              >
                + Add criterion
              </button>
            </template>
          </FloatingTextInput>
        </div>
      </div>
    </div>

    <div>
      <div class="flex-col">
        <p class="text-sm text-primary font-medium">Exclusion criteria</p>
        <div class="flex flex-wrap gap-2 items-center">
          <div
            v-for="criterion in studyDesign.exclusionCriteria"
            :key="'studyDesign-exclusion-criterion-' + criterion"
            class="flex items-center text-sm gap-1 px-3 py-1 min-h-6 rounded-full text-red-800 bg-red-200 leading-none"
          >
            {{ criterion }}
            <button @click="removeCriterion('exclusionCriteria', criterion)">
              <XCircleIcon class="w-5 h-5" />
            </button>
          </div>
          <FloatingTextInput @save="addCriterion('exclusionCriteria', $event)">
            <template #activator="{ show }">
              <button
                key=""
                class="text-sm border-2 p-1 px-3 rounded-full text-primary border-blue-200 border-dashed"
                @click.stop="show"
              >
                + Add criterion
              </button>
            </template>
          </FloatingTextInput>
        </div>
      </div>
    </div>

    <div>
      <div class="flex-col">
        <p class="text-sm text-primary font-medium">Primary outcome</p>
        <div class="flex flex-wrap gap-2 items-center">
          <div
            v-for="criterion in studyDesign.primaryOutcome"
            :key="'studyDesign-primary-outcome-' + criterion"
            class="flex items-center text-sm gap-1 px-3 py-1 min-h-6 rounded-full text-green-800 bg-green-200 leading-none"
          >
            {{ criterion }}
            <button @click="removeCriterion('primaryOutcome', criterion)">
              <XCircleIcon class="w-5 h-5" />
            </button>
          </div>
          <FloatingTextInput @save="addCriterion('primaryOutcome', $event)">
            <template #activator="{ show }">
              <button
                key=""
                class="text-sm border-2 p-1 px-3 rounded-full text-primary border-blue-200 border-dashed"
                @click.stop="show"
              >
                + Add outcome
              </button>
            </template>
          </FloatingTextInput>
        </div>
      </div>
    </div>

    <div>
      <div class="flex-col">
        <p class="text-sm text-primary font-medium">Secondary outcome</p>
        <div class="flex flex-wrap gap-2 items-center">
          <div
            v-for="criterion in studyDesign.secondaryOutcome"
            :key="'studyDesign-secondary-outcome-' + criterion"
            class="flex items-center text-sm gap-1 px-3 py-1 min-h-6 rounded-full text-green-800 bg-green-200 leading-none"
          >
            {{ criterion }}
            <button @click="removeCriterion('secondaryOutcome', criterion)">
              <XCircleIcon class="w-5 h-5" />
            </button>
          </div>
          <FloatingTextInput @save="addCriterion('secondaryOutcome', $event)">
            <template #activator="{ show }">
              <button
                key=""
                class="text-sm border-2 p-1 px-3 rounded-full text-primary border-blue-200 border-dashed"
                @click.stop="show"
              >
                + Add outcome
              </button>
            </template>
          </FloatingTextInput>
        </div>
      </div>
    </div>

    <div>
      <p class="text-sm text-primary font-medium">Controlled</p>
      <Switch
        v-model="studyDesign.controlled"
        @update:model-value="
          (value: boolean) => updateStudyDesignProperty('controlled', value)
        "
      />
    </div>

    <div v-if="studyDesign.direction !== StudyDesignDirection.Retrospective">
      <p class="text-sm text-primary font-medium">Participant blinding</p>
      <Switch
        v-model="studyDesign.participantBlinding"
        @update:model-value="
          (value: boolean) =>
            updateStudyDesignProperty('participantBlinding', value)
        "
      />
    </div>

    <div v-if="studyDesign.direction !== StudyDesignDirection.Retrospective">
      <p class="text-sm text-primary font-medium">Researcher blinding</p>

      <Switch
        v-model="studyDesign.researcherBlinding"
        @update:model-value="
          (value: boolean) =>
            updateStudyDesignProperty('researcherBlinding', value)
        "
      />
    </div>
    <div v-if="studyDesign.direction !== StudyDesignDirection.Retrospective">
      <p class="text-sm text-primary font-medium">Analysis blinding</p>

      <Switch
        v-model="studyDesign.analysisBlinding"
        @update:model-value="
          (value: boolean) =>
            updateStudyDesignProperty('analysisBlinding', value)
        "
      />
    </div>
    <div v-if="studyDesign.direction !== StudyDesignDirection.Retrospective">
      <p class="text-sm text-primary font-medium">Follow up</p>

      <Switch
        v-model="studyDesign.followUp"
        @update:model-value="
          (value: boolean) => updateStudyDesignProperty('followUp', value)
        "
      />
    </div>

    <div v-if="studyDesign.followUp">
      <p class="text-sm text-primary font-medium">Follow up time</p>

      <InlineTextInput
        v-model="studyDesign.followUpTime"
        class="h-full w-full"
        editor-classes="h-full"
        :multiline="true"
        @save="updateStudyDesignProperty('followUpTime', $event)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { SnackbarState } from '@app/types'
import { errorMessage } from '@app/utils/error-message'
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '@app/views/Review/use-review'
import { ReviewItem } from '@core/domain/models/reviewItem.model'
import { HttpException } from '@core/exceptions/http.exception'
import { StudyDesignStatus } from '@core/domain/types/studyDesignStatus.type'
import Checkbox, {
  CheckboxState,
} from '@app/components/Global/Inputs/Checkbox.vue'
import { StudyDesign } from '@core/domain/models/studyDesign.model'
import { reactive, ref } from 'vue'
import { StudyDesignDirection } from '@core/domain/models/studyDesignDirection.model'
import { StudyDesignType } from '@core/domain/models/studyDesignType.model'
import deepClone from 'lodash/cloneDeep'
import GenericAutoComplete from '@app/components/Global/Inputs/GenericAutoComplete/GenericAutoComplete.vue'
import { countries } from '@app/utils/countries'
import FloatingTextInput from '@app/components/Global/Inputs/FloatingTextInput.vue'
import XCircleIcon from '@app/components/Icons/XCircleIcon.vue'
import Switch from '@app/components/Global/Switch.vue'
import InlineTextInput from '@app/components/Global/Inputs/InlineTextInput.vue'

const props = defineProps<{
  study: ReviewItem
}>()

const review = injectStrict(ReviewKey)

const studyDesignNotApplicableCheckbox = ref<CheckboxState>(
  props.study.studyDesignStatus === StudyDesignStatus.applicable
    ? CheckboxState.uncheked
    : CheckboxState.checked,
)

async function toggleStudyDesignStatusCheckbox() {
  if (studyDesignNotApplicableCheckbox.value === CheckboxState.checked) {
    await setStudyDesignStatus(props.study.id, StudyDesignStatus.applicable)
    studyDesignNotApplicableCheckbox.value = CheckboxState.uncheked
  } else {
    await setStudyDesignStatus(props.study.id, StudyDesignStatus.notApplicable)
    studyDesignNotApplicableCheckbox.value = CheckboxState.checked
  }
}

async function setStudyDesignStatus(
  studyId: number,
  studyDesignStatus: StudyDesignStatus,
) {
  try {
    loading.start()
    await review.setStudyDesignStatus(studyId, studyDesignStatus)
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    loading.stop()
  }
}

const originalStudyDesign = deepClone(
  props.study.studyDesign ?? ({} as StudyDesign),
)

const types = [
  {
    text: 'Interventional',
    value: StudyDesignType.Interventional,
  },
  {
    text: 'Observational',
    value: StudyDesignType.Observational,
  },
]

const directions = [
  {
    text: 'Prospective',
    value: StudyDesignDirection.Prospective,
  },
  {
    text: 'Retrospective',
    value: StudyDesignDirection.Retrospective,
  },
  {
    text: 'Combined prospective and retrospective',
    value: StudyDesignDirection.CombinedProspectiveAndRetrospective,
  },
]

const studyDesign = reactive<StudyDesign>({
  type: originalStudyDesign?.type ?? '',
  direction: originalStudyDesign?.direction ?? '',
  design: originalStudyDesign?.design ?? '',
  controlled: originalStudyDesign?.controlled ?? false,
  participantBlinding: originalStudyDesign?.participantBlinding ?? false,
  researcherBlinding: originalStudyDesign?.researcherBlinding ?? false,
  analysisBlinding: originalStudyDesign?.analysisBlinding ?? false,
  followUp: originalStudyDesign?.followUp ?? false,
  followUpTime: originalStudyDesign?.followUpTime ?? '',
  countries: originalStudyDesign?.countries ?? [],
  sites: 0,
  exclusionCriteria: originalStudyDesign.exclusionCriteria ?? [],
  inclusionCriteria: originalStudyDesign.inclusionCriteria ?? [],
  primaryOutcome: originalStudyDesign.primaryOutcome ?? [],
  secondaryOutcome: originalStudyDesign.secondaryOutcome ?? [],
})

async function updateStudyDesignProperty<Key extends keyof StudyDesign>(
  key: Key,
  value: StudyDesign[Key],
  cb: () => void = () => {},
) {
  studyDesign[key] = value
  await updateStudyDesign(studyDesign, cb)
}

const snackbar = useSnackbar()
const loading = useLoading()

async function updateStudyDesign(
  studyDesign: StudyDesign,
  cb: () => void = () => {},
) {
  try {
    loading.start()
    await review.updateStudyDesign(props.study.id, studyDesign)
    Object.assign(originalStudyDesign, deepClone(studyDesign))
    snackbar.success('Study design updated successfully')
    cb()
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    Object.assign(studyDesign, deepClone(originalStudyDesign))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    loading.stop()
  }
}

async function addCountry(countryCode: string) {
  if (!studyDesign.countries.includes(countryCode)) {
    studyDesign.countries.push(countryCode)
    await updateStudyDesign(studyDesign)
  }
}

async function removeCountry(countryCode: string) {
  const index = studyDesign.countries.indexOf(countryCode)

  if (index !== -1) {
    studyDesign.countries.splice(index, 1)
    await updateStudyDesign(studyDesign)
  }
}

async function addCriterion(
  key:
    | 'exclusionCriteria'
    | 'inclusionCriteria'
    | 'primaryOutcome'
    | 'secondaryOutcome',
  criterion: string,
) {
  if (!studyDesign[key].includes(criterion)) {
    studyDesign[key].push(criterion)
    await updateStudyDesign(studyDesign)
  }
}

async function removeCriterion(
  key:
    | 'exclusionCriteria'
    | 'inclusionCriteria'
    | 'primaryOutcome'
    | 'secondaryOutcome',
  criterion: string,
) {
  const index = studyDesign[key].indexOf(criterion)
  if (index > -1) {
    studyDesign[key].splice(index, 1)
    await updateStudyDesign(studyDesign)
  }
}
</script>
