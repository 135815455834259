<template>
  <div class="px-4 py-4 space-y-6 container">
    <h3 class="text-2xl text-primary font-medium flex items-center gap-2">
      Evaluators
    </h3>
    <div class="flex gap-1">
      <template v-for="member in project?.users" :key="member.id">
        <Draggable
          :id="member?.id"
          v-slot="{ isDragged }"
          @update:is-dragged="
            (isDragged: boolean) =>
              updateDraggedItem(member?.id, DraggedItemType.member, isDragged)
          "
        >
          <div
            class="rounded-full dark:text-white text-black text-center max-w-fit py-1 pr-3 pl-1.5 flex gap-1.5 items-center whitespace-nowrap hover:cursor-pointer"
            :class="{
              'border-white text-white': isDragged,
              'border-transparent': !isDragged,
            }"
            :style="{
              'background-color': `${usersColors[member?.id ?? '']}40`,
            }"
          >
            <div
              class="w-5 h-5 p-1 rounded-full text-xs flex items-center justify-center"
              :style="{
                backgroundColor: usersColors[member?.id ?? ''],
              }"
            >
              <p class="h-3 w-3 font-medium leading-3">
                {{ buildUserFullname(member)[0].toUpperCase() }}
              </p>
            </div>
            <div class="leading-none">
              {{ buildUserFullname(member) }}
            </div>
          </div>
        </Draggable>
      </template>
    </div>
    <div>
      <template v-for="role in ['Author', 'Reviewer', 'Approver']" :key="role">
        <DropZone
          v-slot="{ isDraggedOver }"
          class="group flex"
          @dropped="addMemberToRole(role)"
        >
          <div
            class="border-2 py-1 px-2 border-black/20 border-b-0 group-last:border-b-2 min-w-32 md:min-w-64"
          >
            {{ role }}
          </div>
          <div
            :class="{
              'bg-primary/10': isDraggedOver,
            }"
            class="border-2 py-1 px-2 border-black/20 border-b-0 border-l-0 group-last:border-b-2 w-full flex gap-1"
          >
            <div
              v-for="member in members.filter((m) => m.role === role)"
              :key="member.user.id"
              :class="{
                'pointer-events-none': isDraggedOver,
              }"
            >
              <div
                class="rounded-full dark:text-white text-black text-center max-w-fit py-1 pr-3 pl-1.5 flex gap-1.5 items-center whitespace-nowrap"
                :style="{
                  'background-color': `${usersColors[member.user.id ?? '']}40`,
                }"
              >
                <div
                  class="w-5 h-5 p-1 rounded-full text-xs flex items-center justify-center"
                  :style="{
                    backgroundColor: usersColors[member.user.id ?? ''],
                  }"
                >
                  <p class="h-3 w-3 font-medium leading-3">
                    {{ buildUserFullname(member.user)[0].toUpperCase() }}
                  </p>
                </div>
                <div class="leading-none">
                  {{ buildUserFullname(member.user) }}
                </div>
              </div>
            </div>
          </div>
        </DropZone>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '../../use-review'
import { computed, ref } from 'vue'
import { User } from '@core/domain/models/user.model'
import Draggable from '@app/components/Draggable.vue'
import DropZone from '@app/components/DropZone.vue'

enum DraggedItemType {
  member,
  externalMember,
}
const draggedItem = ref<{ id: string; type: DraggedItemType | undefined }>({
  id: '',
  type: undefined,
})
function updateDraggedItem(
  itemId: string,
  type: DraggedItemType,
  state: boolean,
) {
  if (state) {
    draggedItem.value.id = itemId
    draggedItem.value.type = type
  } else {
    draggedItem.value.id = ''
    draggedItem.value.type = undefined
  }
}

const members = ref<{ role: string; user: User }[]>([])

const review = injectStrict(ReviewKey)
const project = review.entity.value.project

const availableColors = [
  '#A1CAE9',
  '#AFB5F1',
  '#C8AFF1',
  '#F6BBFB',
  '#F8AFD2',
  '#F8AFAF',
  '#F8C9AF',
  '#F8DFAF',
  '#EBE28F',
  '#C2F8AF',
  '#AFF8C3',
  '#AFF8EB',
  '#83D6E9',
  '#C8BFFF',
  '#FFBFDA',
  '#FFB6BA',
  '#FFC19E',
  '#D5DF9A',
  '#B8DF9A',
  '#9ADFAD',
  '#B1F1FF',
  '#84B5FF',
  '#8491FF',
  '#AA8CFF',
  '#FFB8FC',
  '#FF9595',
]

const usersColors = computed<{ [userId: string]: string }>(
  () =>
    project?.users?.reduce(
      (a, u, i) => ({ ...a, [u.id]: availableColors[i] }),
      {},
    ) ?? {},
)

function buildUserFullname(user: User) {
  if (!user) return ''
  let fullName = ''
  if (user.firstName) {
    fullName += user.firstName
  }
  if (user.lastName) {
    fullName += ' ' + user.lastName
  }
  if (!fullName) {
    fullName = user.email
  }
  return fullName
}

function addMemberToRole(role: string) {
  const user = project?.users?.find((u) => u.id === draggedItem.value.id)
  if (!user) return
  if (!members.value.some((m) => m.user.id === user.id && m.role === role))
    members.value.push({ role, user })
}
</script>
