<template>
  <div class="flex">
    <div v-lazy-show="isShown">
      <div
        v-if="review.entity.value?.studies?.length > 0"
        class="inline-grid pr-4 pb-4"
        :style="gridStyle"
      >
        <template v-if="shouldShowHeaders">
          <div
            class="border-t-[16px] border-t-white bg-white border-l-[16px] border-l-white sticky top-0 left-0 z-[51]"
          >
            <div
              class="rounded-tl-md bg-primary justify-between border-r border-[#D9E4EA] text-white flex p-3.5 items-center h-full font-medium"
            >
              <div class="flex items-center gap-2">
                <Sort />
                <div>
                  <button
                    class="text-white block"
                    @click="isMetadataExpanded = !isMetadataExpanded"
                  >
                    <ArrowsPointingInIcon
                      v-if="isMetadataExpanded"
                      class="h-5"
                    />
                    <ArrowsPointingOutIcon v-else class="h-5" />
                  </button>
                </div>
              </div>
              <div class="flex items-center gap-2">
                <button
                  v-if="review.displayOptions.isScreeningStageActive.value"
                  v-tooltip="'Toggle showing only favourites'"
                  class="block"
                  @click.stop="toggleFavoriteFilter"
                >
                  <StarIcon
                    class="w-6 h-6 stroke-2"
                    :class="[
                      showOnlyFavourites
                        ? 'fill-amber-400 stroke-amber-400'
                        : 'fill-transparent stroke-[#A8B0B9] hover:fill-amber-400/80 hover:stroke-amber-400/80',
                    ]"
                  />
                </button>
                <SearchAndHighlight />
              </div>
            </div>
          </div>
          <div
            v-if="review.displayOptions.isScreeningStageActive.value"
            class="border-t-[16px] border-t-white sticky top-0 left-0 z-40 min-w-[330px]"
          >
            <div
              class="bg-primary rounded-tr-md border-r border-[#D9E4EA] text-white flex p-3.5 items-center h-full font-medium justify-between"
            >
              Screening
              <button
                class="hover:bg-primary/10 rounded-l-md border-black/20 border flex items-center"
              >
                <button class="relative" @click="toggleChart">
                  <div
                    v-if="review.filtering.hasFiltersApplied.value"
                    class="absolute"
                  >
                    <span class="relative flex h-2 w-2">
                      <span
                        class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"
                      ></span>
                      <span
                        class="relative inline-flex rounded-full h-2 w-2 bg-red-500"
                      ></span>
                    </span>
                  </div>
                  <ChartPieIcon class="w-5 h-auto" />
                </button>
              </button>
            </div>
          </div>

          <template v-if="review.displayOptions.isStudyDesignStageActive.value">
            <div
              class="border-t-[16px] border-t-white sticky top-0 left-0 z-40"
            >
              <div
                class="bg-primary justify-between border-r border-[#D9E4EA] text-white flex p-3.5 items-center h-full font-medium"
              >
                Study design
              </div>
            </div>
          </template>

          <template v-if="review.displayOptions.isAppraisalStageActive.value">
            <div
              v-if="
                review.entity.value?.plan?.appraisalPlan
                  .isImdrfMdce2019Applicable
              "
              class="border-t-[16px] border-t-white sticky top-0 left-0 z-40"
            >
              <div
                class="bg-primary border-r border-[#D9E4EA] text-white flex p-3.5 items-center h-full font-medium"
              >
                IMDRF MDCE 2019
              </div>
            </div>
            <div
              v-if="
                review.entity.value?.plan?.appraisalPlan
                  .isOxfordLevelOfEvidenceApplicable
              "
              class="border-t-[16px] border-t-white sticky top-0 left-0 z-40"
            >
              <div
                class="bg-primary border-r border-[#D9E4EA] text-white flex p-3.5 items-center h-full font-medium"
              >
                Oxford LOE
              </div>
            </div>
            <div
              v-if="
                review.entity.value?.plan?.appraisalPlan
                  .isPeerReviewStatusApplicable
              "
              class="border-t-[16px] border-t-white sticky top-0 left-0 z-40"
            >
              <div
                class="bg-primary rounded-tr-md border-r border-[#D9E4EA] text-white flex p-3.5 items-center h-full font-medium"
              >
                Peer review status
              </div>
            </div>
          </template>

          <template v-if="review.displayOptions.isSynthesisStageActive.value">
            <div
              v-for="(attributeStructure, attributeStructureIndex) in review
                .currentAttributesStructure.value"
              :key="attributeStructure.id"
              class="border-t-[16px] border-t-white sticky top-0 left-0 z-40"
            >
              <div
                v-tooltip="
                  attributeStructure.label +
                  '<br/>' +
                  attributeStructure.question
                "
                class="bg-primary justify-between border-r border-[#D9E4EA] text-white flex p-3.5 items-center h-full font-medium"
                :class="{
                  'rounded-tr-md':
                    attributeStructureIndex ===
                    review.currentAttributesStructure.value.length - 1,
                }"
              >
                <div class="truncate">{{ attributeStructure.label }}</div>
              </div>
            </div>
          </template>
        </template>

        <StudyCard
          v-for="study in review.filteredStudies.value"
          :key="study.id"
          :study="study"
          :screening-stage="review.displayOptions.isScreeningStageActive.value"
          :synthesis-stage="review.displayOptions.isSynthesisStageActive.value"
          :study-design-stage="
            review.displayOptions.isStudyDesignStageActive.value
          "
          :is-metadata-expanded="isMetadataExpanded"
        />
      </div>
      <div v-else class="px-10 pt-8 inline-flex flex-col">
        This review is empty
      </div>
    </div>

    <ScreeningChart
      :model-value="shouldShowChart"
      @update:model-value="setChartShown"
    />
  </div>
</template>
<script setup lang="ts">
import StudyCard from '@app/views/Review/Execute/Studies/Card/StudyCard.vue'
import { computed, ref, watch } from 'vue'
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '../use-review'
import Sort from '@app/views/Review/Execute/Sort/Sort.vue'
import SearchAndHighlight from './SearchAndFilters/SearchAndHighlight.vue'
import {
  ArrowsPointingOutIcon,
  ChartPieIcon,
  StarIcon,
  ArrowsPointingInIcon,
} from '@heroicons/vue/24/outline'
import { StudyState } from '@app/types'
import ScreeningChart from './ScreeningChart.vue'
import { ExecuteStep } from './DisplayOptions/display-options'

const props = defineProps<{
  isShown: boolean
  activeStep: Step
}>()

watch(
  () => props.activeStep,
  (v) => {
    toggleStep(v)
  },
)

enum Step {
  screening = 1,
  studyDesign = 2,
  appraisal = 3,
  synthesis = 4,
}
const review = injectStrict(ReviewKey)

function toggleStep(step: Step) {
  switch (step) {
    case Step.screening:
      review.displayOptions.setActiveExecuteStep(ExecuteStep.Screening)
      review.filtering.resetFilters()
      break
    case Step.appraisal:
      review.displayOptions.setActiveExecuteStep(ExecuteStep.Appraisal)
      review.filtering.filterStudiesByState(StudyState.INCLUDED)
      break
    case Step.synthesis:
      review.displayOptions.setActiveExecuteStep(ExecuteStep.Synthesis)
      review.filtering.filterStudiesByState(StudyState.INCLUDED)
      break
    case Step.studyDesign:
      review.displayOptions.setActiveExecuteStep(ExecuteStep.StudyDesign)
      review.filtering.filterStudiesByState(StudyState.INCLUDED)
      break
  }
}

const showOnlyFavourites = computed(() => {
  const filters = review.filtering.filters.value ?? {}
  return Object.keys(filters).every(
    (k) =>
      (k === StudyState.FAVOURITE && filters[k] === true) ||
      (k !== StudyState.FAVOURITE && filters[k as StudyState] === false),
  )
})
function toggleFavoriteFilter() {
  if (!showOnlyFavourites.value)
    review.filtering.filterStudiesByState(StudyState.FAVOURITE)
  else review.filtering.resetFilters()
}

const isMetadataExpanded = ref(true)

const gridStyle = computed(() => {
  let style = `grid-template-columns:${isMetadataExpanded.value ? 600 : 400}px`
  if (review.displayOptions.isScreeningStageActive.value)
    style += ' max-content'
  if (review.displayOptions.isStudyDesignStageActive.value) style += ' 600px'
  if (review.displayOptions.isAppraisalStageActive.value) {
    if (review.entity.value?.plan?.appraisalPlan.isImdrfMdce2019Applicable) {
      style += ' max-content'
    }
    if (
      review.entity.value?.plan?.appraisalPlan.isOxfordLevelOfEvidenceApplicable
    ) {
      style += ' max-content'
    }
    if (review.entity.value?.plan?.appraisalPlan.isPeerReviewStatusApplicable) {
      style += ' max-content'
    }
  }

  if (review.displayOptions.isSynthesisStageActive.value)
    review.currentAttributesStructure.value.forEach(() => {
      style += ' 400px'
    })
  return style
})

const isChartShown = ref(true)

const shouldShowChart = computed(() => {
  return (
    isChartShown.value &&
    review.displayOptions.isScreeningStageActive.value &&
    review.entity.value?.studies?.length > 0 &&
    props.activeStep === Step.screening
  )
})

function toggleChart() {
  isChartShown.value = !isChartShown.value
}

function setChartShown(value: boolean) {
  isChartShown.value = value
}

const shouldShowHeaders = computed(() => {
  return (
    (review.displayOptions.isScreeningStageActive.value &&
      review.entity.value?.studies?.length > 0) ||
    ((review.displayOptions.isAppraisalStageActive.value ||
      review.displayOptions.isSynthesisStageActive.value) &&
      review.filteredStudies.value.length > 0 &&
      review.entity.value?.studies?.length > 0) ||
    (review.displayOptions.isStudyDesignStageActive.value &&
      review.entity.value?.studies?.length > 0)
  )
})
</script>
